import { pbx, api, apiCancelable, apiAbortable } from '@p/app/service/backend/index.ts'
import type { Item, TableCombined, PbxSipAdd, PbxSipEdit, PbxSipNumberItem, DataTablePbxSip } from '@pitman/pbx-api'
import { type Getter } from '@p/app/types/Getter.ts'

export function nodeAutocomplete (filter: TableCombined, tenantId?: number, nodeId?: number): Getter<Item[]> {
  return apiCancelable(pbx, 'pbxSipNodeAutocomplete', filter, tenantId, nodeId)
}

export async function add (sipId: number, data: PbxSipAdd): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxSipAdd', sipId, data)
}

export async function edit (sipId: number, data: PbxSipEdit): Promise<number> {
  return await api({ alert: true, task: true }, pbx, 'pbxSipEdit', sipId, data)
}

export function table (table: TableCombined, tenantId?: number, nodeId?: number): Getter<DataTablePbxSip> {
  return apiCancelable(pbx, 'pbxSipNodeTable', table, tenantId, nodeId)
}

export async function count (tenantId: number): Promise<number> {
  return await api({ alert: true, task: false }, pbx, 'pbxSipNodeCount', tenantId)
}

export function numbersAutocomplete (table: TableCombined, tenantId?: number, nodeId?: number): Getter<PbxSipNumberItem[]> {
  return apiCancelable(pbx, 'pbxSipNumbersAutocomplete', table, tenantId, nodeId)
}

export async function pbxNumbersAutocomplete (signal: AbortSignal, table: TableCombined, tenantId?: number, nodeId?: number): Promise<PbxSipNumberItem[]> {
  return await apiAbortable(signal, pbx, 'pbxSipNumbersAutocomplete', table, tenantId, nodeId)
}

export function hasConflictSipname (nodeId: number, sipname: string): Getter<boolean> {
  return apiCancelable(pbx, 'pbxSipConflictSipname', nodeId, sipname)
}

export function hasConflictUsername (nodeId: number, username: string, kind: number): Getter<boolean> {
  return apiCancelable(pbx, 'pbxSipConflictUsername', nodeId, username, kind)
}

export function hasConflictRegistercode (nodeId: number, registerCode: string, kind: number): Getter<boolean> {
  return apiCancelable(pbx, 'pbxSipConflictRegisterCode', nodeId, registerCode, kind)
}

export function hasConflictSubscribercode (nodeId: number, areaCode: number, subscriberCode: string): Getter<boolean> {
  return apiCancelable(pbx, 'pbxSipConflictSubscriberCode', nodeId, areaCode, subscriberCode)
}

export function hasConflictRoutesIn (nodeId: number, routesIn: string): Getter<boolean> {
  return apiCancelable(pbx, 'pbxSipConflictRoutesIn', nodeId, routesIn)
}

export function hasConflictUserReference (nodeId: number, multiNumberId: number): Getter<number[]> {
  return apiCancelable(pbx, 'pbxSipConflictUserReference', nodeId, multiNumberId)
}
