import type { PbxForwardingAdd, PbxForwardingCheck, PbxForwardingEdit, PbxForwardingSphere, PbxForwardingTrigger, PbxPrivileges, PbxUserAdd as UserAdd, TableQuery, TableCombined } from '@pitman/pbx-api'
import { type Getter } from '@p/rules/types/getter'
import { pbxNumbersAutocomplete } from '@p/app/service/backend/pbx/sip'

export type PbxUserAdd = {
  type?: number
} & UserAdd

export interface PbxForwardingAdapter {
  metadataIdentifier: string
  privilegesSelect: PbxPrivileges
  privilegesInsert: PbxPrivileges
  privilegesUpdate: PbxPrivileges
  privilegesRemove: PbxPrivileges
  table: (id: number, filter: TableQuery) => Getter<{
    items: any
    total: number
  }>
  check: (
    entitiyId: number,
    item: {
      id: number
      sphere: PbxForwardingSphere
      trigger: PbxForwardingTrigger
      condition: number | null
    },
    itemId: number
  ) => Promise<PbxForwardingCheck>
  add: (id: number, data: PbxForwardingAdd) => Promise<number>
  edit: (id: number, data: PbxForwardingEdit) => Promise<number>
  remove: (id: number) => Promise<number>
}

export async function getSipNumberTableAbortable (signal: AbortSignal, filter: TableCombined, tenantId?: number): Promise<Array<{ text: string, value: number, msn: boolean, name: string }>> {
  let numbers = await pbxNumbersAutocomplete(signal, filter, tenantId)
  numbers = numbers.sort((a, b) => {
    if (a.text < b.text) {
      return -1
    }
    if (a.text > b.text) {
      return 1
    }

    return 0
  })
  return numbers.map((number) => ({
    text: number.text + (number.name ? ' (' + number.name + ')' : ''),
    value: number.value,
    msn: number.msn,
    name: number.name
  }))
}
